<template>
  <div receiver-tink>
    <TinkPending v-if="showPending" />
    <TinkFailed v-if="showFailed" :status="status" :failedStatus="failedStatus" :result="result" :tryCount="tryCount" @click="clickTrigger" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import {sleep} from "@shared/utils/commonUtils.mjs";
import TinkController from '@/plugins/tinkController';
import TinkPending from '@/views/components/pages/on-boarding/template/kyc/tink/TinkPending.vue';
import TinkFailed from '@/views/components/pages/on-boarding/template/kyc/tink/TinkFailed.vue';
import {tinkStatus} from '@/constants/base/onboarding/kyc';

export default {
  name: 'ReceiverTink',
  components: { TinkPending, TinkFailed },
  data() {
    return {
      status: tinkStatus.Pending,
      failedStatus: null,
      result: null,
      tryCount: null,
      tink: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    tinkStatus() {
      return tinkStatus;
    },
    showPending() {
      return this.status === tinkStatus.Pending;
    },
    showFailed() {
      return [tinkStatus.Aborted, tinkStatus.SessionExpired, tinkStatus.Failed].includes(this.status);
    },
  },
  methods: {
    /**
     * 컨트롤러에서 결과 콜백
     * @param info
     */
    callback(info) {
      console.log('%ctink receiver callback', 'color:yellow', { status: info.status, failedStatus: info.failedStatus, result: info.result, tryCount: info.tryCount, url: info.url});
      this.status = info.status;
      this.failedStatus = info.failedStatus;
      this.result = info.result;
      this.tryCount = info.tryCount ? Number(info.tryCount) : 0;

      if (this.status === tinkStatus.Ready) this.tink.redirectTink();
      else if (this.status === tinkStatus.Verified) this.replaceRouteName('Replace');
    },
    clickTrigger(trigger) {
      if (trigger === 'replaceJumio') this.replaceRouteName('Jumio', { route: 'Jumio' });
      else if (trigger === 'personalDetail') this.replaceRouteName('ReCheckPersonalDetails', { route: 'ReCheckPersonalDetails' }); // 실제로 사용하진 않으나 우선 유지(Retry 버튼 클릭 시 tryAgain으로 변경 됨)
      else if (trigger === 'tryAgain') this.tink.tryAgain(); // tink url 조회 후 sofort 재시도
    }
  },
  async mounted() {
    const { $services: services, $router: router, site, lang, callback } = this;

    this.tink = new TinkController(this, { services, router, site, lang, callback });

    await sleep(60);
    await this.tink.updateStatus(tinkStatus.Pending);
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[receiver-tink] {}
</style>