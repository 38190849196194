<template>
  <div tink-pending>
    <div class="progress-title">
      <h1>{{ $t('_.tinkFailedTitle') }}</h1>
      <p class="progress"><span :style="{width:'100%'}" /></p>
    </div>
    <FontIcon name="auto-refresh" />
    <h3 v-html="$t('_.tinkPendingSubject')" />
    <label v-html="$t('_.tinkPendingTimer', { mm, ss })" />
    <p v-html="$t('_.tinkPendingDescription', {csEmail})" />
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import {sofortStatus, tinkStatus} from '@/constants/base/onboarding/kyc';
import ColorButton from '@shared/components/common/ColorButton.vue';
import baseTemplate from '@/mixins/baseTemplate';
import SofortController from '@/plugins/sofortController';
import {state} from "@shared/utils/storeUtils.mjs";
export default {
  name: 'TinkPending',
  lexicon: 'kyc.deGiude',
  components: { FontIcon },
  data() {
    return {
      limitMilliSecond: 3 * 60 * 1000, // 3분
      currentMilliSeconds: null,
      intervalId: null,
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
    mm() {
      const minute = Math.trunc(this.currentMilliSeconds / (60 * 1000));
      return this.addLeadingZero(minute, 2);
    },
    ss() {
      const minuteSeconds = Math.trunc(this.currentMilliSeconds / 1000);
      const second = minuteSeconds % 60;
      return this.addLeadingZero(second, 2);
    },
  },
  methods: {
    addLeadingZero(num, length) {
      return num.toString().padStart(length, '0');
    },
  },
  mounted() {
    this.currentMilliSeconds = this.limitMilliSecond;
    const intervalMilliSeconds = 1000;
    this.intervalId = window.setInterval(() => {
      this.currentMilliSeconds -= intervalMilliSeconds;
      if (this.currentMilliSeconds <= 0) {
        window.clearInterval(this.intervalId);
        this.intervalId = null;
      }
    }, intervalMilliSeconds);
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[tink-pending] { .tc();
  .progress-title {
    h1 { .tl(); .medium(); .fs(28); .c(white); .flex(); .items-center(); .wf(); .regular(); line-height: 1.2; }
    .progress { .wh(100%, 2); .rel(); .bgc(rgba(255, 255, 255, 0.07)); .block(); .mt(16);
      span { .block(); .wh(0, 2); .bgc(@c-red); }
    }
  }
  > [font-icon] { .block(); .mb(24); .p(6); .fs(60); animation: rotate-circle 2s linear infinite; .mt(24); }
  > h3 { .fs(22, 27, normal); .regular(); .mb(12); }
  > label { .block(); .fs(16, 22, normal); .c(#7F7F7F); .mb(32); }
  > p { .fs(18, 22, normal); .c(#fff);
    > a[href^='mailto'] { .c(#2680EB); .underline(); }
  }
}
</style>
